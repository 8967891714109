@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap);
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
   
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.navHeader {
    width: 100%;
    position: fixed;
    background: black;
    left: 0;
    top: 0%;
    /* go from 0%-94% on link click */
    z-index: 500;
    height: 64px;
    max-height: 64px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
#atTop{
    top: 0%;
}

#moveToBottom{
    top:94%;
    transition:top .75s ease-out;
}

#atTopOpacity{
    opacity: 1;
}

#moveToBottomOpacity{
    opacity: 0;
    transition: .5s ease-in-out;
}

.navBarBG{
    background-color: black;
}

.nav-link{
    font-family: 'Barlow', sans-serif;
    color: white !important;
}

.nav-link:hover{
    font-family: 'Barlow', sans-serif;
    color: #cb3939 !important;
}



.topDisplay {
    display: block;
    min-width: 480px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: black;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:auto;
  }
  
  .logo {
    width: 100%;
  }
  
  div.featuredText{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  span.tagLineFirst {
    font-size: 2rem;
    color: white;
  }
  
  span.tagLineSecond {
    font-size: 5rem;
    color: #7b0000;
  }
  
  @media only screen and (max-width: 825px) {
  
    .grid-item {
      margin: 0 auto;
      padding-left: 5%;
    }
  
    div.artistName {
      z-index: 1;
    }
    
    .overlay_buttons {
      padding-left: 5%;
    }
    #donate {
      padding-left: 5%;
    }
  
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease-in;
      z-index: 2;
    }
  
  }
  
  
  @media only screen and (min-width: 825px) {
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
      opacity: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      z-index: 2;
    }
  
    .grid-item:hover > .overlay{
      opacity: 1;
    }
    
    .grid-item:hover > .artist_picture{
      filter: blur(.5rem);
      transition: .5s ease-in-out;
    }
  
    div.artistName {
      z-index: 1;
    }
  
  }
  
  .grid-item {
    margin: 0 auto;
    
  }
  
  .grid-item  .artist_picture{
    max-width: 400px;
    min-width: 400px;
  }
  
  div.artistName {
    position: absolute;
    left: -5%;
    top: 55%;
    
  }
  
  span.artistName {
    font-size: 3rem;
    color: #7b0000;
  }
  
  p.artistName {
    font-family: 'Barlow', sans-serif;
    font-size: 2rem;
    color: black;
    background: rgba(255, 255, 255, 0.747);
  
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2), 0 0 10px rgba(0,0,0,0.1);
    animation-name: floating;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes floating {
    from {
      transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
    65% {
      transform: translate(0, 15px) rotateY(10deg) rotateX(10deg);
      text-shadow: -5px -5px 3px rgba(0,0,0,0.15);
    }
    to {
      transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
  }
  
  .overlay_buttons{
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .break {
    width: 100%;
  }
  
  .topButtons{
    display: block;
    min-width: 480px;
    text-align: center;
    margin: 10px auto;
  }
  
  #intrinsicLinks {
    display: inline-block;
  }
  
  .social_icon {
    max-width: 20%;
  }
  
  #donate {
    width: 35%;
  }
  
  button.hamburger {
    width: 0px !important;
    height: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  div{
    left: 0px;
  }
  
  
  /* Search bar (header) */
  .noUserSelect {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .page-header {
      width: 100%;
      position: fixed;
      background: black;
    top: 0;
    left: 0;
      z-index: 500;
      height: 64px;
      max-height: 64px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .page-header-buttons-image {
    height: 64px;
  }
  
  .searchBarMain {
      position:relative;
      min-width: 480px;
      background: #fff;
      width: 60%;
      max-width: 680px;
      margin: 0 auto;
      height: 42px;
      margin-top: 11px;
      border-radius: 2px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
      transition: all 0.2s ease-in-out;
  }
  
  .searchBarMain i.searchBarSearchIcon {
      height: 100%;
      line-height: 42px;
      float: left;
      width: 52px;
      text-align: center;
      color: rgba(68, 68, 68, 0.5);
  }
  
  .searchBarMain input#searchBarInput {
      height: 42px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      box-sizing: border-box;
      background: transparent;
      width: calc(100% - 52px);
      outline: none;
      padding-right: 32px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.76);
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
  }
  
  .searchBarMain i.clearSearchBarField {
      position:absolute;
      right: 12px;
      top: 5px;
      color: rgba(0, 0, 0, 0.84);
      font-size: 20px;
  }
  
  .searchBarMain i.clearSearchBarField:hover {
      cursor: pointer;
  }
  
  /*Return to top button*/
  
  #topButton {
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Firefox < 16 */
  
  /* Safari, Chrome and Opera > 12.1 */
  
  /* Internet Explorer */
  
  /* Opera < 12.1 */
  
  .slick-prev {
    left: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }

  .fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 

  
  
  
  
  
  
  
  
  
  
  
  
  
@media only screen and (max-width: 825px) {
    .grid-item-latest{
        margin: 0 auto;
      }
}

@media only screen and (min-width: 825px) {
    .grid-item-latest{
        margin: 0 auto;
      }
}

div.featuredText{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: black;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: 3rem;
}

span.tagLineFirst {
font-size: 1.5rem;
color: black;
}

span.tagLineSecond {
font-size: 3rem;
color: #7b0000;
}

.fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 

a.tagButton{
    display:inline-block;
    background: #7b0000;
    color: #ffffff;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
}
div.featuredTextAbout{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: white;
    letter-spacing: 0.01rem;
    text-transform: lowercase;
    line-height: 1.5rem;
}

span.tagLineFirstAbout {
font-size: 1.5rem;
color: white;
}

span.tagLineFirstAboutBody {
    font-size: 1rem;
    color: white;
    letter-spacing: 0;
    text-transform: none;
    line-height: 0;
}

span.tagLineSecondAbout {
font-size: 3rem;
line-height: 6rem;
color: #7b0000;
}

span.tagLineSecondAboutHeader {
    font-size: 3rem;
    line-height: 1rem;
    color: #7b0000;
    }
    
.textContainer{
    width: 70%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:auto;
    opacity: 1;

  }

  .fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 
a.keyword {
    font-family: 'Barlow', sans-serif;
    text-decoration: none;
    font-size: 4em;
    color: white;
}

a.keyword:hover {
    color: #7b0000;
}

.fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 

.nowPlayingContainer {
    display: block;
    position: relative;
    top: 30px;
}

.vidPlayer {
    width: 414px;
    margin: 0 auto;
    font-size: 1em;
}

.playListSearch {
    max-width: 414px;
    margin: 0 auto;
}

.playlistName {
    text-align: center;
    font-size:5em;
}

.channelThumbnail {
    width: 100px;
}

.info {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.flex-child-thumb {
    flex:.25 1;
    margin: 1px;
}

.flex-child-description {
    flex:1 1;
    height: 100px;
    background-color: white;
    color: white;
    font-size: 90%;
}
.flex-new-notification{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-direction: row;
    font-weight: bold;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #7b0000;  
    color: white;  
    letter-spacing: 10px; 
}

a.tagButton{
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
}

#backButton {
    display: block;
    position: fixed;
    top: 10%;
    left: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  .noUserSelect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

  .searchBarPlaylistMain {
    position: fixed;
    background: darkgray;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    top: 0;
    /* left: 25%; */
    height: 42px;
    margin-top: 11px;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.623);
    transition: all 0.2s ease-in-out;
}

.searchBarPlaylistMain i.searchBarSearchIcon {
    height: 100%;
    line-height: 42px;
    float: left;
    width: 52px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.searchBarPlaylistMain input#searchBarInput {
    height: 42px;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: center;
    box-sizing: border-box;
    background: transparent;
    width: calc(100% - 52px);
    outline: none;
    padding-right: 32px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.76);
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.searchBarPlaylistMain i.clearSearchBarField {
    position:absolute;
    right: 12px;
    top: 5px;
    color: rgba(255, 255, 255, 0.84);
    font-size: 20px;
}

.searchBarPlaylistMain i.clearSearchBarField:hover {
    cursor: pointer;
}


.topDisplay {
    display: block;
    min-width: 480px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: black;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:auto;
  }

  .coverArt {
    width: 300px;
    height: 300px;
  }
  
  .logo {
    width: 100%;
  }
  
  div.featuredText{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  span.tagLineFirst {
    font-size: 2rem;
    color: white;
  }
  
  span.tagLineSecond {
    font-size: 5rem;
    color: #7b0000;
  }
  
  @media only screen and (max-width: 825px) {
  
    .grid-item {
      margin: 0 auto;
      padding-left: 5%;
    }
  
    div.artistName {
      z-index: 1;
    }
    
    .overlay_buttons {
      padding-left: 5%;
    }
    #donate {
      padding-left: 5%;
    }
  
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease-in;
      z-index: 2;
    }
  
  }
  
  
  @media only screen and (min-width: 825px) {
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
      opacity: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      z-index: 2;
    }
  
    .grid-item:hover > .overlay{
      opacity: 1;
    }
    
    .grid-item:hover > .artist_picture{
      filter: blur(.5rem);
      transition: .5s ease-in-out;
    }
  
    div.artistName {
      z-index: 1;
    }
  
  }
  
  .grid-item {
    margin: 0 auto;
    
  }
  
  .grid-item  .artist_picture{
    max-width: 400px;
    min-width: 400px;
  }
  
  div.artistName {
    position: absolute;
    left: -5%;
    top: 55%;
    
  }
  
  span.artistName {
    font-size: 3rem;
    color: #7b0000;
  }
  
  p.artistName {
    font-family: 'Barlow', sans-serif;
    font-size: 2rem;
    color: black;
    background: rgba(255, 255, 255, 0.747);
  
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2), 0 0 10px rgba(0,0,0,0.1);
    animation-name: floating;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes floating {
    from {
      transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
    65% {
      transform: translate(0, 15px) rotateY(10deg) rotateX(10deg);
      text-shadow: -5px -5px 3px rgba(0,0,0,0.15);
    }
    to {
      transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
  }
  
  .overlay_buttons{
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .break {
    width: 100%;
  }
  
  .topButtons{
    display: block;
    min-width: 480px;
    text-align: center;
    margin: 10px auto;
  }
  
  #intrinsicLinks {
    display: inline-block;
  }
  
  .social_icon {
    max-width: 20%;
  }
  
  #donate {
    width: 35%;
  }
  
  button.hamburger {
    width: 0px !important;
    height: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  div{
    left: 0px;
  }
  
  
  /* Search bar (header) */
  .noUserSelect {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .page-header {
      width: 100%;
      position: fixed;
      background: black;
    top: 0;
    left: 0;
      z-index: 500;
      height: 64px;
      max-height: 64px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .page-header-buttons-image {
    height: 64px;
  }
  
  .searchBarMain {
      position:relative;
      min-width: 480px;
      background: #fff;
      width: 60%;
      max-width: 680px;
      margin: 0 auto;
      height: 42px;
      margin-top: 11px;
      border-radius: 2px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
      transition: all 0.2s ease-in-out;
  }
  
  .searchBarMain i.searchBarSearchIcon {
      height: 100%;
      line-height: 42px;
      float: left;
      width: 52px;
      text-align: center;
      color: rgba(68, 68, 68, 0.5);
  }
  
  .searchBarMain input#searchBarInput {
      height: 42px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      box-sizing: border-box;
      background: transparent;
      width: calc(100% - 52px);
      outline: none;
      padding-right: 32px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.76);
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
  }
  
  .searchBarMain i.clearSearchBarField {
      position:absolute;
      right: 12px;
      top: 5px;
      color: rgba(0, 0, 0, 0.84);
      font-size: 20px;
  }
  
  .searchBarMain i.clearSearchBarField:hover {
      cursor: pointer;
  }
  
  /*Return to top button*/
  
  #topButton {
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Firefox < 16 */
  
  /* Safari, Chrome and Opera > 12.1 */
  
  /* Internet Explorer */
  
  /* Opera < 12.1 */
  
  .slick-prev {
    left: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }

  .fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
}

@media only screen and (max-width: 825px) {
    .radioButtons {
      text-align: center;
      display: block;
      position: relative;
      top: 0px;
  }  
}


@media only screen and (min-width: 825px) {
  .radioButtons {
      text-align: center;
      display: block;
      position: relative;
      top: 125px;
  }
}

/* Specifies the size of the audio container */
audio {
  width: 115px;
  height: 25px;
}

audio::-webkit-media-controls-panel {
  -webkit-justify-content: center;
  height: 25px;
}

/* Removes the timeline */
audio::-webkit-media-controls-timeline {
  display: none !important;
}

/* Removes the time stamp */
audio::-webkit-media-controls-current-time-display {
  display: none;
}
audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

  
  
  
  
  
  
  
  
  
  
  
  
  
body {background-color: #000000; font-family: 'Barlow', sans-serif;}
.topDisplay {
    display: block;
    min-width: 480px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: #7a7a7a;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: .25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:auto;
  }
  
  .logo {
    width: 100%;
  }
  
  
  @media only screen and (max-width: 825px) {
    .grid-item {
      margin: 0 auto;
      padding-left: 5%;
    }
    .grid-item-no-art {
      display:inline-block;
      margin: 0 auto;
      padding-left: 5%;
      width: 300px;
      height: 300px;
      border: 1px solid #353535;
      font-size: 100%;
      text-align: center;
      vertical-align: middle;
      line-height: 60px; 
      word-wrap: break-word;
      white-space: pre-wrap;
      text-decoration: none;
      color:#ffffff;
      background:#353535;
      font-weight: bold;
    }
  
    div.date {
      z-index: 1;
      background: #ffffff;
    }

    div.price {
      z-index: 1;
      background: #ffffff;
    }

    div.genre {
      background: #ffffff;
      z-index: 1;
    }

    #overlayText{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 10px;
      color: white;
      z-index: 9999;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    }

    .helpVisible {
      display: block;
      position: fixed;
      top: 10%;
      right: .5%;
      color: #7a7a7a;
      z-index: 99; 
    }
  
  }
  
  
  @media only screen and (min-width: 825px) {
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      z-index: 2;
    }
  
    div.date {
      z-index: 1;
      background: #ffffff;
    }

    div.price {
      z-index: 1;
      background: #ffffff;
    }

    div.genre {
      z-index: 1;
      background: #ffffff;
    }

    #overlayText{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.5em;
      color: white;
      z-index: 9999;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    }

    .helpVisible {
      display: block;
      position: fixed;
      top: .5%;
      right: .5%;
      color: #7a7a7a;
      z-index: 99; 
    }
  
  }
  
  a.grid-item {
    text-decoration : none
  }

  .grid-item {
    margin: 5 auto;
  }

  .grid-item-no-art {
    display:inline-block;
    margin: 5 auto;
    width: 300px;
    height: 300px;
    border: 1px solid #353535;
    font-size: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 60px; 
    word-wrap: break-word;
    text-decoration: none;
    white-space: pre-wrap;
    color:#ffffff;
    background:#353535;
    font-weight: bold;
  }
  
  .grid-item  .artist_picture{
    max-width: 400px;
    min-width: 400px;
  }

  div.date {
    position: absolute;
    right: 5%;
    bottom: 0%;
    background:#7a7a7a;
  }
  
  span.date {
    font-size: 3rem;
    color: #ffeed0;
    background:#7a7a7a;
  }
  
  p.date {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #ffffff;
    background:#7a7a7a;
    
  }
  
  div.price {
    transform: rotate(-15deg);
    position: absolute;
    left: 5%;
    top: 5%;
    height:1em;
    background: #ffffff;
  }
  
  span.price {
    font-size: 3rem;
    background: #ffffff;
  }
  
  p.price {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #7a7a7a;
    background: #ffffff;
  }

  p.priceNotification {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #7a7a7a;
    background: #ffffff;
    border: 2px solid;
    border-color: red;
  }

  div.genre {
    position: absolute;
    right: 5%;
    bottom: 5%;
    background: #7a7a7a;
  }
  
  span.genre {
    font-size: 2rem;
    background: #7a7a7a;
  }

  /* unvisited link */
  a:link {
    color: #ffffff;
  }

  /* visited link */
  a:visited {
    color: #ffffff;
  }

  /* mouse over link */
  a:hover {
    color: #ffffff;
  }

  /* selected link */
  a:active {
    color: #ffffff;
  }

  p.genre {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    text-align: right;
    color: #ffffff;
    padding: 10px 10px 0px 0px;
    background: #7a7a7a;
  }
  
  .overlay_buttons{
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .break {
    width: 100%;
  }
  
  .topButtons{
    display: block;
    min-width: 480px;
    text-align: center;
    margin: 10px auto;
  }
  
  #intrinsicLinks {
    display: inline-block;
  }
  
  .social_icon {
    max-width: 20%;
  }
  
  #donate {
    width: 35%;
  }
  
  button.hamburger {
    width: 0px !important;
    height: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  div{
    left: 0px;
  }
  
  
  /* Search bar (header) */
  .noUserSelect {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .page-header {
      width: 100%;
      position: fixed;
      background: #32161F;
    top: 0;
    left: 0;
      z-index: 500;
      height: 64px;
      max-height: 64px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .page-header-buttons-image {
    height: 64px;
  }
  
  .searchBarMain {
      position:relative;
      min-width: 480px;
      background: #fff;
      width: 60%;
      max-width: 680px;
      margin: 0 auto;
      height: 42px;
      margin-top: 11px;
      border-radius: 2px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
      transition: all 0.2s ease-in-out;
  }
  
  .searchBarMain i.searchBarSearchIcon {
      height: 100%;
      line-height: 42px;
      float: left;
      width: 52px;
      text-align: center;
      color: rgba(68, 68, 68, 0.5);
  }
  
  .searchBarMain input#searchBarInput {
      height: 42px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      box-sizing: border-box;
      background: transparent;
      width: calc(100% - 52px);
      outline: none;
      padding-right: 32px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.76);
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
  }
  
  .searchBarMain i.clearSearchBarField {
      position:absolute;
      right: 12px;
      top: 5px;
      color: rgba(0, 0, 0, 0.84);
      font-size: 20px;
  }
  
  .searchBarMain i.clearSearchBarField:hover {
      cursor: pointer;
  }

  .fade-in-image {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  /*Return to top button*/
  
  #topButton {
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: #7a7a7a;
    color: #32161F;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  #badge {
    position: absolute;
    font-size: 15px;
    top: -8px;
    left: -10px;
    padding: 0px 5px;
    border-radius: 100%;
    background: red;
    color: white;
  }

  .helpNotVisible{
    display: block;
    position: fixed;
    visibility: hidden;
  }

  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.801); /* Black background with opacity */
    z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  





  
  
  
  
  
  
  
  
  
  
  
  
  
 .react-tags {
  position:relative;
  min-width: 500px;
  background: #7a7a7a;
  width: 60%;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 11px;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
  transition: all 0.2s ease-in-out;
  z-index:999;

    padding: 0 0px 0px 6px;
  
    /* shared font styles */
    font-size: 1em;
  
    /* clicking anywhere will focus the input */
    cursor: text;
  }
  
  .react-tags.is-focused {
    border-color: #B1B1B1;
  }
  
  .react-tags__selected {
    display: inline;
  }
  
  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: .75% .25% 0 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background:#353535;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  .react-tags__selected-tag-name{
    background:#353535;
  }
  
  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
  }
  
  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #B1B1B1;
  }
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
      color:black;
    }
  
  }
  
  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    background: #7a7a7a;
    color:black;
  }
  
  .react-tags__search-input::-ms-clear {
    display: none;
    color:black;
  }
  
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color:black;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__suggestions {
      width: 240px;
    }
  
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #353535;
    opacity: 1; /* Firefox */
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #353535;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #353535;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #353535;
  }
