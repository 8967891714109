 .react-tags {
  position:relative;
  min-width: 500px;
  background: #7a7a7a;
  width: 60%;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 11px;
  border-radius: 2px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index:999;

    padding: 0 0px 0px 6px;
  
    /* shared font styles */
    font-size: 1em;
  
    /* clicking anywhere will focus the input */
    cursor: text;
  }
  
  .react-tags.is-focused {
    border-color: #B1B1B1;
  }
  
  .react-tags__selected {
    display: inline;
  }
  
  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: .75% .25% 0 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background:#353535;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  .react-tags__selected-tag-name{
    background:#353535;
  }
  
  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
  }
  
  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #B1B1B1;
  }
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
      color:black;
    }
  
  }
  
  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    background: #7a7a7a;
    color:black;
  }
  
  .react-tags__search-input::-ms-clear {
    display: none;
    color:black;
  }
  
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color:black;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__suggestions {
      width: 240px;
    }
  
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #353535;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #353535;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #353535;
  }