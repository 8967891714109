@media only screen and (max-width: 825px) {
    .grid-item-latest{
        margin: 0 auto;
      }
}

@media only screen and (min-width: 825px) {
    .grid-item-latest{
        margin: 0 auto;
      }
}

div.featuredText{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: black;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: 3rem;
}

span.tagLineFirst {
font-size: 1.5rem;
color: black;
}

span.tagLineSecond {
font-size: 3rem;
color: #7b0000;
}

.fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 

a.tagButton{
    display:inline-block;
    background: #7b0000;
    color: #ffffff;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
}