a.keyword {
    font-family: 'Barlow', sans-serif;
    text-decoration: none;
    font-size: 4em;
    color: white;
}

a.keyword:hover {
    color: #7b0000;
}

.fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
} 

.nowPlayingContainer {
    display: block;
    position: relative;
    top: 30px;
}

.vidPlayer {
    width: 414px;
    margin: 0 auto;
    font-size: 1em;
}

.playListSearch {
    max-width: 414px;
    margin: 0 auto;
}

.playlistName {
    text-align: center;
    font-size:5em;
}

.channelThumbnail {
    width: 100px;
}

.info {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.flex-child-thumb {
    flex:.25;
    margin: 1px;
}

.flex-child-description {
    flex:1;
    height: 100px;
    background-color: white;
    color: white;
    font-size: 90%;
}
.flex-new-notification{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-direction: row;
    font-weight: bold;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: #7b0000;  
    color: white;  
    letter-spacing: 10px; 
}

a.tagButton{
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
}

#backButton {
    display: block;
    position: fixed;
    top: 10%;
    left: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  .noUserSelect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

  .searchBarPlaylistMain {
    position: fixed;
    background: darkgray;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    top: 0;
    /* left: 25%; */
    height: 42px;
    margin-top: 11px;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.623);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.searchBarPlaylistMain i.searchBarSearchIcon {
    height: 100%;
    line-height: 42px;
    float: left;
    width: 52px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.searchBarPlaylistMain input#searchBarInput {
    height: 42px;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: center;
    box-sizing: border-box;
    background: transparent;
    width: calc(100% - 52px);
    outline: none;
    padding-right: 32px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.76);
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.searchBarPlaylistMain i.clearSearchBarField {
    position:absolute;
    right: 12px;
    top: 5px;
    color: rgba(255, 255, 255, 0.84);
    font-size: 20px;
}

.searchBarPlaylistMain i.clearSearchBarField:hover {
    cursor: pointer;
}
