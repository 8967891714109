
.topDisplay {
    display: block;
    min-width: 480px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: black;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: .25rem;
    width: fit-content;
    height: fit-content;
    margin:auto;
  }

  .coverArt {
    width: 300px;
    height: 300px;
  }
  
  .logo {
    width: 100%;
  }
  
  div.featuredText{
    text-align:center;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  span.tagLineFirst {
    font-size: 2rem;
    color: white;
  }
  
  span.tagLineSecond {
    font-size: 5rem;
    color: #7b0000;
  }
  
  @media only screen and (max-width: 825px) {
  
    .grid-item {
      margin: 0 auto;
      padding-left: 5%;
    }
  
    div.artistName {
      z-index: 1;
    }
    
    .overlay_buttons {
      padding-left: 5%;
    }
    #donate {
      padding-left: 5%;
    }
  
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease-in;
      z-index: 2;
    }
  
  }
  
  
  @media only screen and (min-width: 825px) {
    .hamburger-box {
      position: absolute;
      top: 5%;
      bottom: 0;
      left: 80%;
      right: 0;
      opacity: 0;
    }
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      z-index: 2;
    }
  
    .grid-item:hover > .overlay{
      opacity: 1;
    }
    
    .grid-item:hover > .artist_picture{
      filter: blur(.5rem);
      transition: .5s ease-in-out;
    }
  
    div.artistName {
      z-index: 1;
    }
  
  }
  
  .grid-item {
    margin: 0 auto;
    
  }
  
  .grid-item  .artist_picture{
    max-width: 400px;
    min-width: 400px;
  }
  
  div.artistName {
    position: absolute;
    left: -5%;
    top: 55%;
    
  }
  
  span.artistName {
    font-size: 3rem;
    color: #7b0000;
  }
  
  p.artistName {
    font-family: 'Barlow', sans-serif;
    font-size: 2rem;
    color: black;
    background: rgba(255, 255, 255, 0.747);
  
    text-shadow: 2px 2px 2px rgba(0,0,0,0.2), 0 0 10px rgba(0,0,0,0.1);
    
    -webkit-animation-name: floating;
            animation-name: floating;
    -webkit-animation-duration: 15s;
            animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  
  @keyframes floating {
    from {
      -webkit-transform: translate(0, 0px) rotateY(-6deg);
              transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
    65% {
      -webkit-transform: translate(0, 15px) rotateY(10deg) rotateX(10deg);
              transform: translate(0, 15px) rotateY(10deg) rotateX(10deg);
      text-shadow: -5px -5px 3px rgba(0,0,0,0.15);
    }
    to {
      -webkit-transform: translate(0, 0px) rotateY(-6deg);
              transform: translate(0, 0px) rotateY(-6deg);
      text-shadow: 5px 5px 3px rgba(0,0,0,0.15);
    }
  }
  
  .overlay_buttons{
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .break {
    width: 100%;
  }
  
  .topButtons{
    display: block;
    min-width: 480px;
    text-align: center;
    margin: 10px auto;
  }
  
  #intrinsicLinks {
    display: inline-block;
  }
  
  .social_icon {
    max-width: 20%;
  }
  
  #donate {
    width: 35%;
  }
  
  button.hamburger {
    width: 0px !important;
    height: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  div{
    left: 0px;
  }
  
  
  /* Search bar (header) */
  .noUserSelect {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .page-header {
      width: 100%;
      position: fixed;
      background: black;
    top: 0;
    left: 0;
      z-index: 500;
      height: 64px;
      max-height: 64px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .page-header-buttons-image {
    height: 64px;
  }
  
  .searchBarMain {
      position:relative;
      min-width: 480px;
      background: #fff;
      width: 60%;
      max-width: 680px;
      margin: 0 auto;
      height: 42px;
      margin-top: 11px;
      border-radius: 2px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
  }
  
  .searchBarMain i.searchBarSearchIcon {
      height: 100%;
      line-height: 42px;
      float: left;
      width: 52px;
      text-align: center;
      color: rgba(68, 68, 68, 0.5);
  }
  
  .searchBarMain input#searchBarInput {
      height: 42px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      box-sizing: border-box;
      background: transparent;
      width: calc(100% - 52px);
      outline: none;
      padding-right: 32px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.76);
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
  }
  
  .searchBarMain i.clearSearchBarField {
      position:absolute;
      right: 12px;
      top: 5px;
      color: rgba(0, 0, 0, 0.84);
      font-size: 20px;
  }
  
  .searchBarMain i.clearSearchBarField:hover {
      cursor: pointer;
  }
  
  /*Return to top button*/
  
  #topButton {
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  .slick-prev {
    left: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
    font-size: 50px !important;
  }

  .fadeIn { 
    animation: fadeInAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
} 

.fadeOut { 
    animation: fadeOutAnimation ease 1s; 
    animation-iteration-count: 1; 
    animation-fill-mode: forwards; 
    } 

@keyframes fadeOutAnimation { 
    0% { 
        opacity: 1; 
    } 
    100% { 
        opacity: 0; 
    } 
}

@media only screen and (max-width: 825px) {
    .radioButtons {
      text-align: center;
      display: block;
      position: relative;
      top: 0px;
  }  
}


@media only screen and (min-width: 825px) {
  .radioButtons {
      text-align: center;
      display: block;
      position: relative;
      top: 125px;
  }
}

/* Specifies the size of the audio container */
audio {
  width: 115px;
  height: 25px;
}

audio::-webkit-media-controls-panel {
  -webkit-justify-content: center;
  height: 25px;
}

/* Removes the timeline */
audio::-webkit-media-controls-timeline {
  display: none !important;
}

/* Removes the time stamp */
audio::-webkit-media-controls-current-time-display {
  display: none;
}
audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

  
  
  
  
  
  
  
  
  
  
  
  
  