body {background-color: #000000; font-family: 'Barlow', sans-serif;}
.topDisplay {
    display: block;
    min-width: 480px;
    font-family: 'Barlow', sans-serif;
    font-size: 6rem;
    color: #7a7a7a;
    letter-spacing: 0.10em;
    text-transform: lowercase;
    line-height: .25rem;
    width: fit-content;
    height: fit-content;
    margin:auto;
  }
  
  .logo {
    width: 100%;
  }
  
  
  @media only screen and (max-width: 825px) {
    .grid-item {
      margin: 0 auto;
      padding-left: 5%;
    }
    .grid-item-no-art {
      display:inline-block;
      margin: 0 auto;
      padding-left: 5%;
      width: 300px;
      height: 300px;
      border: 1px solid #353535;
      font-size: 100%;
      text-align: center;
      vertical-align: middle;
      line-height: 60px; 
      word-wrap: break-word;
      white-space: pre-wrap;
      text-decoration: none;
      color:#ffffff;
      background:#353535;
      font-weight: bold;
    }
  
    div.date {
      z-index: 1;
      background: #ffffff;
    }

    div.price {
      z-index: 1;
      background: #ffffff;
    }

    div.genre {
      background: #ffffff;
      z-index: 1;
    }

    #overlayText{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 10px;
      color: white;
      z-index: 9999;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    }

    .helpVisible {
      display: block;
      position: fixed;
      top: 10%;
      right: .5%;
      color: #7a7a7a;
      z-index: 99; 
    }
  
  }
  
  
  @media only screen and (min-width: 825px) {
  
    .overlay {
      position: absolute;
      text-align:center;
      top: 20%;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      z-index: 2;
    }
  
    div.date {
      z-index: 1;
      background: #ffffff;
    }

    div.price {
      z-index: 1;
      background: #ffffff;
    }

    div.genre {
      z-index: 1;
      background: #ffffff;
    }

    #overlayText{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 1.5em;
      color: white;
      z-index: 9999;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
    }

    .helpVisible {
      display: block;
      position: fixed;
      top: .5%;
      right: .5%;
      color: #7a7a7a;
      z-index: 99; 
    }
  
  }
  
  a.grid-item {
    text-decoration : none
  }

  .grid-item {
    margin: 5 auto;
  }

  .grid-item-no-art {
    display:inline-block;
    margin: 5 auto;
    width: 300px;
    height: 300px;
    border: 1px solid #353535;
    font-size: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 60px; 
    word-wrap: break-word;
    text-decoration: none;
    white-space: pre-wrap;
    color:#ffffff;
    background:#353535;
    font-weight: bold;
  }
  
  .grid-item  .artist_picture{
    max-width: 400px;
    min-width: 400px;
  }

  div.date {
    position: absolute;
    right: 5%;
    bottom: 0%;
    background:#7a7a7a;
  }
  
  span.date {
    font-size: 3rem;
    color: #ffeed0;
    background:#7a7a7a;
  }
  
  p.date {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #ffffff;
    background:#7a7a7a;
    
  }
  
  div.price {
    transform: rotate(-15deg);
    position: absolute;
    left: 5%;
    top: 5%;
    height:1em;
    background: #ffffff;
  }
  
  span.price {
    font-size: 3rem;
    background: #ffffff;
  }
  
  p.price {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #7a7a7a;
    background: #ffffff;
  }

  p.priceNotification {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    color: #7a7a7a;
    background: #ffffff;
    border: 2px solid;
    border-color: red;
  }

  div.genre {
    position: absolute;
    right: 5%;
    bottom: 5%;
    background: #7a7a7a;
  }
  
  span.genre {
    font-size: 2rem;
    background: #7a7a7a;
  }

  /* unvisited link */
  a:link {
    color: #ffffff;
  }

  /* visited link */
  a:visited {
    color: #ffffff;
  }

  /* mouse over link */
  a:hover {
    color: #ffffff;
  }

  /* selected link */
  a:active {
    color: #ffffff;
  }

  p.genre {
    font-family: 'Barlow', sans-serif;
    font-size: 1rem;
    text-align: right;
    color: #ffffff;
    padding: 10px 10px 0px 0px;
    background: #7a7a7a;
  }
  
  .overlay_buttons{
    display: inline;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .break {
    width: 100%;
  }
  
  .topButtons{
    display: block;
    min-width: 480px;
    text-align: center;
    margin: 10px auto;
  }
  
  #intrinsicLinks {
    display: inline-block;
  }
  
  .social_icon {
    max-width: 20%;
  }
  
  #donate {
    width: 35%;
  }
  
  button.hamburger {
    width: 0px !important;
    height: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  div{
    left: 0px;
  }
  
  
  /* Search bar (header) */
  .noUserSelect {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  .page-header {
      width: 100%;
      position: fixed;
      background: #32161F;
    top: 0;
    left: 0;
      z-index: 500;
      height: 64px;
      max-height: 64px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  
  .page-header-buttons-image {
    height: 64px;
  }
  
  .searchBarMain {
      position:relative;
      min-width: 480px;
      background: #fff;
      width: 60%;
      max-width: 680px;
      margin: 0 auto;
      height: 42px;
      margin-top: 11px;
      border-radius: 2px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.14);
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
  }
  
  .searchBarMain i.searchBarSearchIcon {
      height: 100%;
      line-height: 42px;
      float: left;
      width: 52px;
      text-align: center;
      color: rgba(68, 68, 68, 0.5);
  }
  
  .searchBarMain input#searchBarInput {
      height: 42px;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
      box-sizing: border-box;
      background: transparent;
      width: calc(100% - 52px);
      outline: none;
      padding-right: 32px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.76);
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
  }
  
  .searchBarMain i.clearSearchBarField {
      position:absolute;
      right: 12px;
      top: 5px;
      color: rgba(0, 0, 0, 0.84);
      font-size: 20px;
  }
  
  .searchBarMain i.clearSearchBarField:hover {
      cursor: pointer;
  }

  .fade-in-image {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  /*Return to top button*/
  
  #topButton {
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    z-index: 99;
    font-size: 25px;
    border: none;
    outline: none;
    background-color: #7a7a7a;
    color: #32161F;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  #badge {
    position: absolute;
    font-size: 15px;
    top: -8px;
    left: -10px;
    padding: 0px 5px;
    border-radius: 100%;
    background: red;
    color: white;
  }

  .helpNotVisible{
    display: block;
    position: fixed;
    visibility: hidden;
  }

  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.801); /* Black background with opacity */
    z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  





  
  
  
  
  
  
  
  
  
  
  
  
  